@import "@core/scss/global";

app-admin-table {
  app-admin-tag-list {
    pointer-events: none;
  }
}

.multiline-tooltip {
  white-space: pre-line !important;
}
