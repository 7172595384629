@use '@angular/material' as mat;

$admin-app-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$cyan-palette),
    accent: mat.define-palette(mat.$teal-palette),
    warn: mat.define-palette(mat.$red-palette),
  ),
));

$admin-app-dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette(mat.$cyan-palette),
    accent: mat.define-palette(mat.$teal-palette),
    warn: mat.define-palette(mat.$red-palette),
  ),
));

/*
$admin-app-dev-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$cyan-palette),
    accent: mat.define-palette(mat.$teal-palette),
    warn: mat.define-palette(mat.$red-palette),
  ),
));

$admin-app-dev-dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette(mat.$cyan-palette),
    accent: mat.define-palette(mat.$teal-palette),
    warn: mat.define-palette(mat.$red-palette),
  ),
));

$admin-app-test-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$orange-palette),
    accent: mat.define-palette(mat.$green-palette),
    warn: mat.define-palette(mat.$red-palette),
  ),
));

$admin-app-test-dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette(mat.$orange-palette),
    accent: mat.define-palette(mat.$green-palette),
    warn: mat.define-palette(mat.$red-palette),
  ),
));
*/
