@use '@angular/material' as mat;
@use './theme/theme' as theme;
@use './theme/typography' as typography;
@use './theme/partials/navigation.component-theme' as navigation;
@use './theme/partials/admin-page-theme' as admin-page;
@use './theme/partials/user-permission.component-theme' as user-permission;
@use './theme/partials/colors' as colors;
@import './partials/layout';
@import './partials/admin-page';
@import './partials/table-avatar';

@include mat.core();
@include mat.all-component-typographies(typography.$custom-typography);

body {
  @include mat.all-component-themes(theme.$admin-app-theme);
  @include navigation.theme(theme.$admin-app-theme);
  @include admin-page.theme(theme.$admin-app-theme);
  @include user-permission.theme(theme.$admin-app-theme);
  @include colors.theme(theme.$admin-app-theme);
}

.admin-page .page-header,
mat-sidenav,
{
  @include mat.all-component-colors(theme.$admin-app-dark-theme);
  background: mat.get-color-from-palette(mat.define-palette(mat.$blue-gray-palette), 900) !important;
  color: mat.get-color-from-palette(mat.define-palette(mat.$blue-gray-palette), '500-contrast');
}

.admin-page .page-header {
  background-image: url('~src/assets/images/tree-h3.jpg') !important;
  background-size: cover !important;
  background-blend-mode: color-dodge;
}

.admin-page .page-content > div > mat-nav-list {
  background: white;
  background: linear-gradient(180deg, white 80%, transparent) !important;
  padding: 20px 25px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.admin-page .page-content {
  overflow: auto;
}

html, body {
  height: 100%;
}

.mat-header-cell {
  font-size: 14px !important;
}

body {
  margin: 0;
}

.autosize .mdc-tooltip__surface {
  max-width: unset !important;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, .12);
  border-radius: 12px;
}

.tag[data-value="Prossima Apertura"] {
  background-color: #FFB700 !important;
}

.tag[data-value="Aperto"] {
  background-color: #36AEB5 !important;
}

.tag[data-value="Chiuso"] {
  background-color: #FF0000 !important;
}

.tag[data-value="In Scadenza"] {
  background-color: #FF5500 !important;
}

.tag[data-value="Da valutare"] {
  background-color: #F8976E !important;
}

.tag[data-value="Valutato"] {
  background-color: #6EBAF8 !important;
}

.clickable-content-cell {
  color: var(--primary-color);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.mat-tooltip {
  white-space: pre;
  max-width: initial !important;
}

.mat-header-cell.highlight,
.mat-cell.highlight {
  background: lightblue;
  margin: auto 5px;
}
