@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  app-user-permission {
    sup {
      line-height: 0;
      font-size: 18px;
      color: mat.get-color-from-palette($accent);
    }

  }
}
