app-admin-table {
  mat-table {
    mat-row {
      app-initials-avatar {
        .avatar {
          transition: all 0.2s;
          border: 1px solid transparent;
          width: 28px !important;
          height: 28px !important;
          font-size: 8px !important;

          .wrapper {
            line-height: 28px !important;
          }
        }
      }

      &:not(:hover) {
        .avatar {
          border: 1px solid #999999;
          color: #999999 !important;
          background-color: transparent !important;
        }
      }
    }

  }
}
