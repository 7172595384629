@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  admin-navigation {
    mat-nav-list {
      .mat-mdc-subheader {
        color: mat.get-color-from-palette($primary) !important;
      }
    }

    a.mat-mdc-list-item {
      &.active {
        &::after {
          background: mat.get-color-from-palette($primary) !important;
          background: linear-gradient(270deg, rgba(mat.get-color-from-palette($primary),0) 0%, rgba(mat.get-color-from-palette($primary),1) 100%) !important;
          width: 100% !important;
          z-index: -1;
        }
      }
    }

  }
}
