@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  $config: mat.get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .admin-page {
    .page-content {
      mat-nav-list {
        a.mat-mdc-list-item {
          &.active {
            color: mat.get-contrast-color-from-palette($primary, 700) !important;
            background-color: mat.get-color-from-palette($primary, 700) !important;

            .mdc-list-item__primary-text {
              color: inherit !important;
            }

            &:hover, &:focus {
              background-color: mat.get-color-from-palette($primary, 600) !important;
            }
          }
        }

        & > mat-nav-list {
          a.mat-mdc-list-item {
            .mdc-list-item__content {
              display: flex;
              align-items: center;

              &:before {
                position: relative;
                top: 0;
                content: "";
                margin-right: 20px;
                margin-left: 4px;
                display: inline-block;
                border-left: 2px solid transparent;
                border-bottom: 2px solid transparent;
                width: 6px;
                height: 6px;
                transform: rotate(225deg);
              }
            }
            &.active {
              color: mat.get-color-from-palette($primary, 700) !important;
              background-color: rgba(mat.get-color-from-palette($primary, 700), 0.06) !important;

              .mdc-list-item__content {
                &:before {
                  border-color: mat.get-color-from-palette($primary, 700);
                }

                .mdc-list-item__primary-text {
                  color: inherit !important;
                }
              }

              &:hover, &:focus {
                background-color: rgba(mat.get-color-from-palette($primary, 600), 0.12) !important;
              }
            }
          }
        }
      }
    }

    .carded {
      background: mat.get-color-from-palette($background, 'card') !important;
    }
  }
}
